import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({
  name: 'sort',
  pure: false
})
export class SortPipe implements PipeTransform {

  transform(value: any[], criteria: SortCriteria): any[] {

    if (!value || !criteria)
      return value;
    
    let p: string = criteria.property;

    let sortFn:(a: any, b: any) => any = (a, b) => {
      let aValue = a[p];
      let bValue = b[p];
      let value: number = 0;
      // Check if the field is a date and convert to Date object for comparison
      if (this.isDate(aValue) && this.isDate(bValue)) {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }
      if (this.isBlank(aValue)) value = -1;
      else if (this.isBlank(bValue)) value = 1;
      else value = aValue > bValue ? 1 : (bValue > aValue ? -1 : 0);
      return criteria.descending ? (value * -1) : value;
    };

    value.sort(sortFn);
    return value;
  }
  // Helper function to detect if the value is a valid date
  private isDate(value: any): boolean {
    // Check if the value can be parsed as a valid date
    const date = Date.parse(value);
    return !isNaN(date);
  }
  // Helper function to check for blank, null, or undefined values
  private isBlank(value: any): boolean {
    return value === null || value === undefined || value === '';
  }
}

export interface SortCriteria {
  property: string;
  descending?: boolean;
}