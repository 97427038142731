import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged } from 'rxjs';
import { isSubscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  public selStateName:any='';
  public selDistrictName:any='';
  public selMuncipalArea:any='';
  public selBCCFZone:any='';
  public filterState: any = {
    SelStateID: '0',
    SelDistrictID: '0',
    SelMunicipalAreaID: '0',
    SelBCCFZoneID: '0',
    SelTerritorialAreaID: '0',
    SelAdministratorAreaID: '0',
    SelAdministrativeAreaType:'',
    selectedDateRange: '',
    isButtonClick:''
  };
  
  private filterStateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.filterState);
  private debounceTime = 300; // Debounce time in milliseconds

  private isFilterServiceInitialized: boolean = false;
  resetFilter():void{
    this.filterState = {
      SelStateID: '0',
      SelDistrictID: '0',
      SelMunicipalAreaID: '0',
      SelBCCFZoneID: '0',
      SelTerritorialAreaID: '0',
      SelAdministratorAreaID: '0',
      SelAdministrativeAreaType:'',
      selectedDateRange: '',
      isButtonClick:''
    };
  }
  setFilterValue(controlName: string, value: string,serviceinitalize:boolean): void {
    const previousValue = this.filterState[controlName];
    this.filterState = {
      ...this.filterState,
      [controlName]: value
    };

    this.filterStateSubject.next({
      filterState: this.filterState,
      changedFilterName: controlName,
      changedFilterValue: value,
      previousFilterValue: previousValue
    });
    this.isFilterServiceInitialized  = serviceinitalize;
  }

  getFilterValue(controlName: string): string {
    return this.filterState[controlName];
  }

  getFilterStateChanges(): Observable<any> {
    return this.filterStateSubject.asObservable().pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged()
    );
  }
  isInitialized(): boolean {
    return this.isFilterServiceInitialized;
  }

  setSelStateName(value: string) {
    this.selStateName = value;
  }
  setSelDistrictName(value: string) {
    this.selDistrictName = value;
  }
  setSelMuncipalArea(value: string) {
    this.selMuncipalArea = value;
  }
  setSelBCCFZone(value: string) {
    this.selBCCFZone = value;
  }
}
