import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { StorageService } from '../../login/storage.service';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import jsPDF from 'jspdf';

@Component({
    selector: 'app-administrativearea-profile',
    templateUrl: './administrativearea-profile.component.html',
    styleUrls: ['./administrativearea-profile.component.css'],
    //changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation:ViewEncapsulation.None
  })
  export class AdministrativeAreaProfileComponent implements OnInit {
    @Input() datafor: string = '';    
    @Input() modaltitle: string = '';  
    @Input() valuefor: string = '';
    @Input() valueforid: string = '';
    @Input() SlumProfileData: any={};
    @Output() dataChanged: EventEmitter<void> = new EventEmitter<void>();
    userlogin:any;
  constructor(private rtmisservice:FHIRtmisService,private storageService: StorageService,private el: ElementRef
    ) { 
    this.userlogin=this.storageService.getUser();
    if(this.userlogin){      

    }    
  }
  ngOnChanges(changes: SimpleChanges) {
    // Handle changes to the selected row item
    //console.log(changes['selectedRowItem']);
    
    if (changes['SlumProfileData'] && !changes['SlumProfileData'].firstChange) {
      this.SlumProfileData=changes['SlumProfileData'].currentValue; 
      debugger
      if(this.SlumProfileData!=undefined){
        let administrativeAreaId= this.SlumProfileData.Administrative_Area_id;
        this.rtmisservice.GetMasterData('HouseHold', 'administrativeareaid', administrativeAreaId, 'valuefor', '10000').subscribe(serviceproviders => {
            this.SlumProfileData.hhlist = serviceproviders;
        });		
        this.rtmisservice.GetMasterData('Cluster', 'administrativeareaid', administrativeAreaId, 'valuefor', '10000').subscribe(serviceproviders => {
            this.SlumProfileData.ClusterList = serviceproviders;
        });								    
        this.rtmisservice.GetMasterData('ServiceProvider', 'administrativeareaid', administrativeAreaId, 'valuefor', '10000').subscribe(serviceproviders => {
            this.SlumProfileData.ServiceProviderList = serviceproviders;
        });		
        this.rtmisservice.GetMasterData('OutletData', 'administrativeareaid', administrativeAreaId, '', '').subscribe(outletdata => {
          this.SlumProfileData.OutletList = outletdata;
        });	
        this.rtmisservice.GetMasterData('WaterStagnation', 'administrativeareaid', administrativeAreaId, '', '').subscribe(outletdata => {
          this.SlumProfileData.WSList = outletdata;
        });		    						    
        this.rtmisservice.GetMasterData('SchoolData', 'administrativeareaid', administrativeAreaId, '', '').subscribe(schooldata => {
          this.SlumProfileData.SchoolList = schooldata;
        });
						    
      }
    }    
  }
    ngOnInit(): void {
    }
    backgroundImageUrl(ProfileDataPhoto:any): string {
      // Modify this logic based on your actual requirements
      return ProfileDataPhoto.includes('SlumPhoto') ? 'https://dengue.fhindia.org/'+ProfileDataPhoto : ProfileDataPhoto;
    }
    GetCellValue(filter_col:any,columnid:any){
	    var paramcolumnid=columnid;
	    var filter = {
          ProviderType_id: ''
        };
        
        filter['ProviderType_id']=filter_col;
	    var elementId = [];
	    var datatocheck=[];
	    datatocheck=this.SlumProfileData.ServiceProviderList;
	    if(datatocheck !=undefined){
    	    elementId= datatocheck.filter(function(item:any) {
              for (let key in filter) {
                if(filter['ProviderType_id'] === ''){continue;}
                if (item[key] === undefined || item[key] != filter['ProviderType_id'])
                  return false;
              }
              return true;
            });
	    }
        var retvalue="";
        for(var i=0;i<elementId.length;i++){
            if(retvalue=="")
            {retvalue = elementId[i][columnid];}
            else
            {retvalue = retvalue +', '+ elementId[i][columnid];}
        }
        return retvalue;
	}
  @ViewChild('PdfDiv') PdfDiv!: ElementRef;
  generatePDF() {
    let filename ='Slum Profile-'+this.SlumProfileData.Administrative_Area_Name+'.pdf';
    const content = this.PdfDiv.nativeElement.innerHTML;
    const printWindow = window.open('', '', 'width=600,height=600');

    printWindow?.document.open();
    printWindow?.document.write('<html><head><title>'+ filename +'</title>');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/bootstrap.min.css" rel="stylesheet" />');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/components.min.css" rel="stylesheet" />');
    printWindow?.document.write(`<style>img{
      width: 280px;
      height: 280px;
    }            
    .cellheading{
      font-weight:bold;
      font-size:12pt;
    }                   
    .table td, .table th {
      font-size: 12px !important;
    }</style>`);
    printWindow?.document.write('</head><body onload="window.print();window.close()">');
    printWindow?.document.write('<div>'+content+'</div>');
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();

    printWindow?.print();
    // Wait for the user to finish printing, then close the print window   
    // setTimeout(() => {
    //   printWindow?.close();
    // }, 100);    
  }
  generatePDF1() {
    let filename='SlumProfile';
    let jsPdf = new jsPDF('p', 'pt', 'a4');
    const element = this.el.nativeElement.querySelector('#PdfDiv');
    // you need to load html2canvas (and dompurify if you pass a string to html)
    const opt = {
        callback: function (jsPdf:any) {
            jsPdf.save(filename);
            // to open the generated PDF in browser window
            // window.open(jsPdf.output('bloburl'));
        },
        margin: [72, 72, 72, 72],
        autoPaging: 'text',
        html2canvas: {
            allowTaint: true,
            //dpi: 300,
            letterRendering: true,
            logging: false,
            scale: .8
        }
    };
    const customCss = `<link href="https://rtmis.fhindia.org/assets/css/components.min.css" rel="stylesheet" />`;
    const combinedHtml = customCss + element.innerHTML;
    jsPdf.html(combinedHtml, {
      callback: function (jsPdf:any) {
          jsPdf.save(filename);
          // to open the generated PDF in browser window
          // window.open(jsPdf.output('bloburl'));
      },
      margin: [72, 72, 72, 72],
      autoPaging: 'text',
      html2canvas: {
          allowTaint: true,
          //dpi: 300,
          letterRendering: true,
          logging: false,
          scale: .8
      }
    });
  }
}