import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[restrict-numeric]'
})
export class RestrictNumericDirective {
  @Input() allowDecimal: boolean = false;  // Input to control if decimals are allowed

  private integerPattern = /^[0-9]*$/;          // Pattern for integers only
  private decimalPattern = /^[0-9]+(\.[0-9]*)?$/; // Pattern for decimals

  constructor(private el: ElementRef) {}

  // Listen to keypress event to restrict input
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    const currentValue = this.el.nativeElement.value + inputChar;

    if (this.allowDecimal) {
      // If decimal is allowed, use the decimal pattern
      if (!this.decimalPattern.test(currentValue)) {
        event.preventDefault();
      }
    } else {
      // If decimal is not allowed, use the integer pattern
      if (!this.integerPattern.test(currentValue)) {
        event.preventDefault();
      }
    }
  }

  // Listen to paste event to ensure valid data is pasted
  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData?.getData('text') || '';
    if (this.allowDecimal) {
      if (!clipboardData.match(this.decimalPattern)) {
        event.preventDefault();
      }
    } else {
      if (!clipboardData.match(this.integerPattern)) {
        event.preventDefault();
      }
    }
  }
}
