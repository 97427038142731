<p-toast></p-toast>
<div class="panel">
  <div class="panel-heading">
    <form [formGroup]="selectsForm">
      <div class='row'>
        <div class="col-md-12">
          <div class="form-group">
            <div class="font-red">
              Update Data ID : {{selectsForm.controls['UpdateID'].value}}
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='User'">
          <div class='form-group'>
            <label for="stafftype">Login Type</label>
            <select class='form-control' formControlName="ModalLoginType" [appIncludeIf]="datafor=='User'">
              <option value='State'>State</option>
              <option value='District'>District</option>
              <option value='BCCF'>BCCF</option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!(datafor=='State' || datafor=='ActivityType' || datafor=='ProjectStaffType')">
          <div class="form-group">
            <label for="state">State</label>
            <select class='form-control' formControlName="modalstateid" (change)="onStateChange()"
                    [appIncludeIf]="!(datafor=='State' || datafor=='ActivityType' || datafor=='ProjectStaffType')">
              <option value="0">Select State</option>
              <option *ngFor="let state of states" [value]="state.State_id">{{ state.State_Name }}</option>
            </select>
            <div class="font-red" *ngIf="selectsForm.controls['modalstateid']?.value=='0' && customvalidationerr">
              Please Select State.
            </div>
          </div>
        </div>
        <div class="col-md-6"
             *ngIf="!((datafor=='User' && selectsForm.controls['ModalLoginType']?.value=='State') || datafor=='District' || datafor=='ActivityType' || datafor=='ProjectStaffType' || datafor=='State')">
          <div class="form-group">
            <label for="district">District</label>
            <select class='form-control' id="district" formControlName="modaldistrictid" (change)="onDistrictChange()"
                    [appIncludeIf]="!((datafor=='User' && selectsForm.controls['ModalLoginType']?.value=='State') || datafor=='District' || datafor=='ActivityType' || datafor=='ProjectStaffType' || datafor=='State')">
              <option value="0">Select District</option>
              <option *ngFor="let district of districts || []" [value]="district.District_id">
                {{ district.District_Name }}
              </option>
            </select>
            <div class="font-red" *ngIf="selectsForm.controls['modaldistrictid']?.value=='0' && customvalidationerr">
              Please Select District.
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!((datafor=='User' && (selectsForm.controls['ModalLoginType']?.value=='District' || selectsForm.controls['ModalLoginType']?.value=='State')) || datafor=='MunicipalArea' || datafor=='MeetingPlanning' || datafor=='EmbedTarget' ||
      datafor=='District' || datafor=='ActivityType' || datafor=='ProjectStaffType' || datafor=='State')">
          <div class="form-group">
            <label for="municipalArea">Nagar Nigam / Block</label>
            <select class='form-control' id="municipalArea" formControlName="modalmunicipalAreaid" [appIncludeIf]="!((datafor=='User' && (selectsForm.controls['ModalLoginType']?.value=='District' || selectsForm.controls['ModalLoginType']?.value=='State')) || datafor=='MunicipalArea' || datafor=='MeetingPlanning' || datafor=='EmbedTarget' ||
            datafor=='District' || datafor=='ActivityType' || datafor=='ProjectStaffType' || datafor=='State')"
                    (change)="onMunicipalAreaChange()">
              <option value="0">Select Nagar Nigam / Block</option>
              <option *ngFor="let area of municipalAreas || []" [value]="area.MunicipalArea_id">
                {{ area.MunicipalArea_Name }}
              </option>
            </select>
            <div class="font-red"
                 *ngIf="selectsForm.controls['modalmunicipalAreaid']?.value=='0' && customvalidationerr">
              Please Select Nagar Nigam / Block.
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!((datafor=='User' && (selectsForm.controls['ModalLoginType']?.value=='District' || selectsForm.controls['ModalLoginType']?.value=='State')) || datafor=='MunicipalArea' || datafor=='MeetingPlanning' || datafor=='EmbedTarget' || datafor=='District' || datafor=='ActivityType' || datafor=='ProjectStaffType' || datafor=='State' ||
      datafor=='BCCFZone')">
          <div class="form-group">
            <label for="bccfZone">BCCF Zone</label>
            <select class='form-control' id="bccfZone" formControlName="modalbccfZoneid" (change)="onBCCFZoneChange()" [appIncludeIf]="!((datafor=='User' && (selectsForm.controls['ModalLoginType']?.value=='District' || selectsForm.controls['ModalLoginType']?.value=='State')) || datafor=='MunicipalArea' || datafor=='MeetingPlanning' || datafor=='EmbedTarget' || datafor=='District' || datafor=='ActivityType' || datafor=='ProjectStaffType' || datafor=='State' ||
            datafor=='BCCFZone')">
              <option value="0">Select BCCF Zone</option>
              <option *ngFor="let zone of bccfZones || []" [value]="zone.BCCFZone_id">{{ zone.BCCFZone_Name }}</option>
            </select>
            <div class="font-red" *ngIf="selectsForm.controls['modalbccfZoneid']?.value=='0' && customvalidationerr">
              Please Select BCCF Zone
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!(datafor=='MunicipalArea' || datafor=='MeetingPlanning' || datafor=='EmbedTarget' || datafor=='District' || datafor=='ActivityType' || datafor=='ProjectStaffType' || datafor=='State' || datafor=='BCCFZone'
      || datafor=='TerritorialArea' || datafor=='User')">
          <div class="form-group">
            <label for="territorialArea">Ward / Panchayat</label>
            <select class='form-control' id="territorialArea" formControlName="modalterritorialAreaid" [appIncludeIf]="!(datafor=='MunicipalArea' || datafor=='MeetingPlanning' || datafor=='EmbedTarget' || datafor=='District' || datafor=='ActivityType' || datafor=='ProjectStaffType' || datafor=='State' || datafor=='BCCFZone'
            || datafor=='TerritorialArea' || datafor=='User')"
                    (change)="onTerritorialAreaChange()">
              <option value="0">Select Ward / Panchayat</option>
              <option *ngFor="let area of territorialAreas || []" [value]="area.TerritorialArea_id">
                {{
                area.TerritorialArea_Name
                }}
              </option>
            </select>
            <div class="font-red"
                 *ngIf="selectsForm.controls['modalterritorialAreaid']?.value=='0' && customvalidationerr">
              Please Select Ward / Panchayat.
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!(datafor=='MunicipalArea' || datafor=='MeetingPlanning' || datafor=='EmbedTarget' || datafor=='District' || datafor=='ActivityType' || datafor=='ProjectStaffType' || datafor=='State' || datafor=='BCCFZone' || (datafor=='EModulePlanning' && valuefor=='NIV') || datafor=='TerritorialArea' || datafor=='User'
      || datafor=='AdministrativeArea')">
          <div class="form-group">
            <label for="administrativeArea">Slum / Village</label>
            <select class='form-control' id="administrativeArea" formControlName="modaladministrativeAreaid" [appIncludeIf]="!(datafor=='MunicipalArea' || datafor=='MeetingPlanning' || datafor=='EmbedTarget' || datafor=='District' || datafor=='ActivityType' || datafor=='ProjectStaffType' || datafor=='State' || datafor=='BCCFZone' || (datafor=='EModulePlanning' && valuefor=='NIV') || datafor=='TerritorialArea' || datafor=='User'
            || datafor=='AdministrativeArea')"
            *ngIf="!(datafor=='Activity' && (valuefor=='2' || valuefor=='9' || valuefor=='10' || valuefor=='11' || valuefor=='12' || valuefor=='24'))"
                    (change)="onAdministrativeAreaChange()">
              <option value="0">Select Slum / Village</option>
              <ng-container *ngFor="let area of administrativeAreas">
                <option *ngIf="area?.Administrative_Area_id !== null" [value]="area?.Administrative_Area_id">
                  {{ area?.Administrative_Area_Name }}
                </option>
              </ng-container>
            </select>
            <ng-multiselect-dropdown [settings]="ddlMultiSelectSettings"
                                     [data]="administrativeAreas"
                                     *ngIf="(datafor=='Activity' && (valuefor=='2' || valuefor=='9' || valuefor=='10' || valuefor=='11' || valuefor=='12' || valuefor=='24'))"
                                     formControlName="modalmultipleadministrativeAreaid"
                                     [(ngModel)]="selectedadministrativeAreas"
                                     class="form-control"
                                     (onSelect)="onSelect($event)"
                                     (onDeSelect)="onDeSelect ($event)"
                                     [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>
            <div class="font-red"
                 *ngIf="selectsForm.controls['modaladministrativeAreaid']?.value=='0' && customvalidationerr">
              Please Select Slum / Village
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="datafor=='HouseHold' || (isscaleup_civic && ((datafor=='ChaupalPlanning' && valuefor!='CommunicationActivity') || datafor=='SSVisitPlanning' || (datafor=='ServiceProvider' && valuefor=='9')))">
          <div class="form-group">
            <label for="cluster">Cluster:</label>
            <select class='form-control' id="cluster" formControlName="modalclusterid" [appIncludeIf]="(datafor=='HouseHold' || datafor=='ChaupalPlanning' || datafor=='SSVisitPlanning' || datafor=='ServiceProvider')">
              <option value="0">Select Cluster</option>
              <option *ngFor="let cluster of clusters" [value]="cluster.Cluster_id">{{ cluster.Cluster_Name }}</option>
            </select>
            <div class="font-red" *ngIf="selectsForm.controls['modalclusterid']?.value=='0'  && customvalidationerr">
              Please Select Cluster
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="(datafor=='ActivityPlanning' || (datafor=='Activity' && valuefor!='84'))">
          <div class='form-group'>
            <label>Activity Type</label>
            <select class='form-control' formControlName="PlanningActivityType" [appIncludeIf]="datafor=='ActivityPlanning' || datafor=='Activity'">
              <option value='0'>Select Activity Type</option>
              <option *ngFor="let acttype of activitytypes || []" [value]="acttype.ActivityType_id">
                {{
                  acttype.ActivityType_Name
                }}
              </option>
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="((isscaleup_civic && datafor=='ChaupalPlanning' && valuefor=='CommunicationActivity') || (datafor=='Activity' && (valuefor=='9' || valuefor=='10' || valuefor=='11' || valuefor=='12' || valuefor=='13' || valuefor=='2' || valuefor=='24')))">
          <div class='form-group'>
            <label>{{valuefor=='13'?'Project Staff':(valuefor=='2'?'School':((datafor=='Activity' && valuefor=='24') || (datafor=='ChaupalPlanning' && valuefor=='CommunicationActivity'))?'Cluster':'Service Provider')}}</label>
            <ng-multiselect-dropdown [settings]="ddlMultiClusterSelectSettings"
                                     [data]="clusters"
                                     *ngIf="isscaleup_civic && ((datafor=='Activity' && valuefor=='24') || (datafor=='ChaupalPlanning' && valuefor=='CommunicationActivity'))"
                                     formControlName="modalmultipleclusterid"
                                     [(ngModel)]="selectedClusters"
                                     class="form-control"
                                     [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>
            <ng-multiselect-dropdown [settings]="ddlMultiSelectSettings"
                                     [data]="ModalServiceProviders"
                                     *ngIf="(valuefor=='9' || valuefor=='10' || valuefor=='11' || valuefor=='12')"
                                     formControlName="ModalServiceProviderIDs"
                                     [(ngModel)]="selectedServiceProviders"
                                     class="form-control"
                                     [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>
            <ng-multiselect-dropdown [settings]="ddlMultiSelectSettings"
                                     [data]="masterProjectStaff"
                                     *ngIf="(valuefor=='13')"
                                     class="form-control"
                                     formControlName="ModalProjectStaffIDs"
                                     [(ngModel)]="selectedProjectStaffs"
                                     
                                     [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>
            <ng-multiselect-dropdown [settings]="ddlMultiSelectSettings"
                                     [data]="schooldata"
                                     *ngIf="(valuefor=='2')"
                                     class="form-control"
                                     formControlName="ModalSchoolActIDs"
                                     [(ngModel)]="selectedSchoolAct"
                                     [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Project Information</label>
            <input class='form-control' type="text" formControlName="ProjectInformation" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Project Name</label>
            <input class='form-control' type="text" formControlName="ProjectName" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Name of Team Members</label>
            <input class='form-control' type="text" formControlName="NameofTeamMembers" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-12' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <h4>SECTION A: DETAILS OF MALARIA/DENGUE POSITVE CASE</h4>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Case Identification Number</label>
            <input class='form-control' type="text" formControlName="CaseIdentificationNumber" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Name</label>
            <input class='form-control' type="text" formControlName="participantname" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Father/Husband name</label>
            <input class='form-control' type="text" formControlName="father_husband_name" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Name of Head of Household</label>
            <input class='form-control' type="text" formControlName="household_head" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Household number</label>
            <input class='form-control' type="text" formControlName="household_number" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Address with landmark</label>
            <input class='form-control' type="text" formControlName="address_landmark" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Total number of members in household (include guests/ visitors present during Case Base Activity)</label>
            <input class='form-control' type="text" formControlName="noofmember" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-12' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <h4>SECTION A: SCREENING OF ALL MEMEBRS IN FAMILY OF MALARIA/DENGUE POSITIVE</h4>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Number of persons – blood smear examination done in family of positive (all members of family to be tested regardless of fever symptoms)</label>
            <input class='form-control' type="text" formControlName="noofperson_testdone" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>No. of persons tested positive for Malaria/Dengue</label>
            <input class='form-control' type="text" formControlName="noofperson_testpositive" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>If Malaria number of pf/pv/mixed cases</label>
            <input class='form-control' type="text" formControlName="noof_pfpvmixedcase" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Number of positive cases whom treatment initiated</label>
            <input class='form-control' type="text" formControlName="noofperson_treatmentstarted" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-12' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <h4>SECTION B: FEVER SURVEY IN VILLAGE/BASTI</h4>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Total number of Household in locality</label>
            <input class='form-control' type="text" formControlName="noofhousehold" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>No. of Households where fever survey is conducted</label>
            <input class='form-control' type="text" formControlName="noofhousehold_feversurvey" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>No. of Households where persons identified with fever</label>
            <input class='form-control' type="text" formControlName="noofhousehold_feveridentified" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>No. of fever patients screened for Malaria/Dengue</label>
            <input class='form-control' type="text" formControlName="noofperson_malariadenguescreen" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>No. of persons tested for Malaria/Dengue</label>
            <input class='form-control' type="text" formControlName="noofperson_testedmalden" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>If Malaria number of pf/pv/mixed cases</label>
            <input class='form-control' type="text" formControlName="noofperson_pfpvmixedcase" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-12' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <h4>SECTION C: INTEGRATED VECTOR MANAGEMENT ACTIVITY AT HOUSEHOLD LEVEL</h4>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>No. of Households conducted larva survey</label>
            <input class='form-control' type="text" formControlName="noofhousehold_larvasurved" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>No. of Households where larva found</label>
            <input class='form-control' type="text" formControlName="noofhousehold_larvafound" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Mention source reduction activities conducted in each larva point</label>
            <input class='form-control' type="text" formControlName="sourcereductionactivities" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-12' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <h4>SECTION D: INTEGRATED VECTOR MANAGEMENT ACTIVITY AT COMMUNITY LEVEL</h4>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Total number of water stagnation points in locality</label>
            <input class='form-control' type="text" formControlName="noofwaterstagnation" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>No. of water stagnation points inspected for larva</label>
            <input class='form-control' type="text" formControlName="noofwaterstagnation_inspected" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>No. of water stagnation points where larva found</label>
            <input class='form-control' type="text" formControlName="noofwaterstagnation_larvafound" restrict-numeric [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor=='84'">
          <div class='form-group'>
            <label>Mention type of larva destruction activity by source</label>
            <input class='form-control' type="text" formControlName="typeof_larvadestruction" [appIncludeIf]="(datafor=='Activity' && valuefor=='84')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Activity Conducted By</label>
            <input class='form-control' type="text" formControlName="ModalActivityConductedBy" [appIncludeIf]="(datafor=='Activity')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Activity</label>
            <input class='form-control' type='text' formControlName="ModalActivityName" [appIncludeIf]="(datafor=='Activity')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Venue</label>
            <input class='form-control' type='text' formControlName="ModalActivityVenue" [appIncludeIf]="(datafor=='Activity')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Background</label>
            <input class='form-control' type='text' formControlName="ModalActivityBackground" [appIncludeIf]="(datafor=='Activity')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Objectives</label>
            <input class='form-control' type='text' formControlName="ModalActivityObjectives" [appIncludeIf]="(datafor=='Activity')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Process</label>
            <input class='form-control' type='text' formControlName="ModalActivityProcess" [appIncludeIf]="(datafor=='Activity')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity'">
          <div class='form-group'>
            <label>Activity Date(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="ModalActivityDate" maxlength="10" [appIncludeIf]="(datafor=='Activity')"
                   (keydown)="onKeyDown($event,'ModalActivityDate')" (keyup)="onKeyUp($event,'ModalActivityDate')"
                   [appCustomValidation]="[
                  { type: 'date', minDate: Q1Start, maxDate: CurDate, errorMessage: 'Please enter a valid date before ' + CurDate }
                ]" />
            <div class="font-red" *ngIf="selectsForm.controls['ModalActivityDate'].errors">
              <div *ngIf="selectsForm.controls['ModalActivityDate'].errors['date'].maxDate">
                Please enter a date before {{
                    CurDate | date:'dd-MMM-yyyy'
                }}.
              </div>
              <div *ngIf="selectsForm.controls['ModalActivityDate'].errors['date'].minDate">
                Please enter a date after {{
                    Q1Start | date:'dd-MMM-yyyy'
                }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Time</label>
            <input class='form-control' type='text' formControlName="ModalActivityTime" [appIncludeIf]="(datafor=='Activity')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>No.of Participants</label>
            <input class='form-control' type='text' formControlName="ModalNoofParticipants" restrict-numeric [appIncludeIf]="(datafor=='Activity')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Name of Participants</label>
            <input class='form-control' type='text' formControlName="ModalNameofParticipants" restrict-alphanumeric [appIncludeIf]="(datafor=='Activity')">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Outcome of Meeting</label>
            <input class='form-control' type='text' formControlName="ModalOutcomeofmeeting" [appIncludeIf]="(datafor=='Activity')">
          </div>
        </div>
        <div class='col-md-12' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Activity Photographs</label>
            <app-file-upload [accept]="'image/*'" [maxFiles]="2" [maxSizePerFile]="1048576" [selectedFiles]="ModalActivityPhoto"></app-file-upload>
          </div>
        </div>
        <div class='col-md-12' *ngIf="datafor=='Activity' && valuefor!='84'">
          <div class='form-group'>
            <label>Attendance Sheet</label>
            <app-file-upload [accept]="'image/*'" [maxFiles]="2" [maxSizePerFile]="1048576" [selectedFiles]="ModalActivityAttendance"></app-file-upload>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='HNDSite'">
          <div class='form-group'>
            <label>HND Site</label>
            <input class='form-control' type='text' formControlName="ModalHNDSite" [appIncludeIf]="datafor=='HNDSite'" required>
            <div class="font-red" *ngIf="selectsForm.controls['ModalHNDSite'].errors">
              <div *ngIf="selectsForm.controls['ModalHNDSite'].errors['required']">Enter HND Site.</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='HNDSite'">
          <div class='form-group'>
            <label>HND Fix Day</label>
            <input class='form-control' type='text' formControlName="ModalHNDFixDay" [appIncludeIf]="datafor=='HNDSite'" required>
            <div class="font-red" *ngIf="selectsForm.controls['ModalHNDFixDay'].errors">
              <div *ngIf="selectsForm.controls['ModalHNDFixDay'].errors['required']">Enter HND Site.</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaff'">
          <div class='form-group'>
            <label for="stafftype">Project Staff Type</label>
            <select class='form-control' id="stafftype" formControlName="ModalProjectStaffTypeID" [appIncludeIf]="datafor=='ProjectStaff'">
              <option value="0">Select Project Staff Type</option>
              <option *ngFor="let stafftype of masterProjectStaffType" [value]="stafftype.ProjectStaffType_id">
                {{
                stafftype.ProjectStaffType_Name
                }}
              </option>
            </select>
            <div class="font-red"
                 *ngIf="selectsForm.controls['ModalProjectStaffTypeID']?.value=='0' && customvalidationerr">
              Please Select Project Staff Type
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ServiceProvider'">
          <div class='form-group'>
            <label for="provider_type">Provider Type</label>
            <select class='form-control' id="provider_type" formControlName="ModalProviderTypeID" [appIncludeIf]="datafor=='ServiceProvider'">
              <option value="">Select Provider Type</option>
              <option *ngFor="let providertype of masterProviderType" [value]="providertype.ProviderType_id">
                {{
                providertype.ProviderType_Name
                }}
              </option>
            </select>
            <div class="font-red"
                 *ngIf="selectsForm.controls['ModalProviderTypeID']?.value=='0' && customvalidationerr">
              Please Select Provider Type
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='OutletData'">
          <div class='form-group'>
            <label for="outlettype">Outlet Type</label>
            <select class='form-control' id="outlettype" formControlName="ModalOutletType" [appIncludeIf]="datafor=='OutletData'">
              <option value="0">Select Outlet Type</option>
              <option *ngFor="let outlettype of masterOutletType" [value]="outlettype.OutletType">
                {{
                outlettype.OutletType
                }}
              </option>
            </select>
            <div class="font-red"
                 *ngIf="selectsForm.controls['ModalOutletType']?.value=='0' && customvalidationerr">
              Please Select Outlet Type
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='WaterStagnation'">
          <div class='form-group'>
            <label for="Waterstagnation_type">Water Stagnation Type</label>
            <select class='form-control' id="Waterstagnation_type" formControlName="ModalWaterStagnationTypeID" [appIncludeIf]="datafor=='WaterStagnation'">
              <option value="0">Select Water Stagnation Type</option>
              <option *ngFor="let Waterstagnationtype of masterWaterStagnationType" [value]="Waterstagnationtype.WaterSatgnation_Type_id">
                {{
                Waterstagnationtype.WaterSatgnation_Type_Name
                }}
              </option>
            </select>
            <div class="font-red"
                 *ngIf="selectsForm.controls['ModalWaterStagnationTypeID']?.value=='0' && customvalidationerr">
              Please Select Water Stagnation Type
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='MeetingPlanning'">
          <div class='form-group'>
            <label for="stafftype">Meeting Type</label>
            <select class='form-control' formControlName="ModalMeetingType" [appIncludeIf]="datafor=='MeetingPlanning'">
              <option value='ProjectUpdate'>ProjectUpdate</option>
              <option value='WorkshopMeeting'>WorkshopMeeting</option>
            </select>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="!(datafor=='ActivityPlanning' || datafor=='SupportSupervisionDC' || datafor=='HNDSite' || datafor=='HouseHold' || datafor=='Project_StaffType' || datafor=='CommunityVolunteer' || datafor=='SchoolData' || datafor=='UrbanAshaTraining' || datafor=='OjhaTraining' || datafor=='HCPTraining' || datafor=='CVTraining' || datafor=='Activity' || datafor=='ChaupalPlanning' || datafor=='MeetingPlanning' || datafor=='HNDPlanning' || datafor=='EModulePlanning' || datafor =='SchoolEventPlanning' || datafor=='SSVisitPlanning' || datafor=='EmbedTarget')">
          <div class='form-group'>
            <label>{{ modaltitle}} Name</label>
            <input class='form-control' type='text' formControlName='DataName' restrict-alphanumeric [appIncludeIf]="!(datafor=='ActivityPlanning' || datafor=='SupportSupervisionDC' || datafor=='HNDSite' || datafor=='HouseHold' || datafor=='Project_StaffType'  || datafor=='CommunityVolunteer' || datafor=='SchoolData' || datafor=='UrbanAshaTraining' || datafor=='OjhaTraining' || datafor=='HCPTraining' || datafor=='CVTraining' || datafor=='Activity' || datafor=='ChaupalPlanning' || datafor=='MeetingPlanning' || datafor=='HNDPlanning' || datafor=='EModulePlanning' || datafor =='SchoolEventPlanning' || datafor=='SSVisitPlanning' || datafor=='EmbedTarget')">
            <div class="font-red"
                 *ngIf="selectsForm.controls['DataName']?.value=='' && customvalidationerr">
              Enter {{ modaltitle}} Name
            </div>
          </div>
        </div>  
        <div class='col-md-6'
             *ngIf="datafor=='User'">
          <div class='form-group'>
            <label>Login User ID</label>
            <input class='form-control' type='text' formControlName='ModalLoginUserID' restrict-alphanumeric [appIncludeIf]="datafor=='User'">
            <div class="font-red"
                 *ngIf="selectsForm.controls['ModalLoginUserID']?.value=='' && customvalidationerr">
              Enter Login User ID
            </div>
          </div>
        </div> 
        <div class='col-md-6'
             *ngIf="datafor=='User'">
          <div class='form-group'>
            <label>Login Password</label>
            <input class='form-control' type='text' formControlName='ModalLoginPassword' restrict-alphanumeric [appIncludeIf]="datafor=='User'">
            <div class="font-red"
                 *ngIf="selectsForm.controls['ModalLoginPassword']?.value=='' && customvalidationerr">
              Enter Password
            </div>
          </div>
        </div>       
        <div class='col-md-6' *ngIf="datafor=='OutletData' || datafor=='ServiceProvider'">
          <div class='form-group'>
            <label for="outletadress">{{ modaltitle}} Address</label>
            <input class='form-control' type='text' formControlName='ModalAddress' restrict-alphanumeric [appIncludeIf]="datafor=='OutletData' || datafor=='ServiceProvider'" required>
            <div class="font-red" *ngIf="selectsForm.controls['ModalAddress'].errors">
              <div *ngIf="selectsForm.controls['ModalAddress'].errors['required']">Enter {{ modaltitle}} Address</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaff' || datafor=='ServiceProvider'">
          <div class='form-group'>
            <label for="stafftype">{{ modaltitle}} Mobile</label>
            <input class='form-control' type='text' formControlName='ModalMobile' restrict-numeric [appIncludeIf]="datafor=='ProjectStaff' || datafor=='ServiceProvider'">

          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaff'">
          <div class='form-group'>
            <label for="stafftype">Project Staff Email</label>
            <input class='form-control' type='text' formControlName='ModalPSemail' [appIncludeIf]="datafor=='ProjectStaff'">

          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaff'">
          <div class='form-group'>
            <label for="stafftype">Project Staff Official Email</label>
            <input class='form-control' type='text' formControlName='ModalPSOfficialemail' [appIncludeIf]="datafor=='ProjectStaff'">

          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaff'">
          <div class='form-group'>
            <label for="stafftype">Education</label>
            <input class='form-control' type='text' formControlName='ModalEducation' [appIncludeIf]="datafor=='ProjectStaff'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaff'">
          <div class='form-group'>
            <label for="stafftype">Experience (in years)</label>
            <input class='form-control' type='text' formControlName='ModalExperience' restrict-numeric [appIncludeIf]="datafor=='ProjectStaff'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="(datafor=='EModulePlanning' && valuefor=='IV') || (datafor=='ServiceProvider' && valuefor=='1')">
          <div class='form-group'>
            <label>{{valueforid=='1'?"Asha Name":(valueforid=='8'?"HCP Name":(valueforid=='9'?"CV Name":"Service Provider"))}}</label>
            <select class='form-control' formControlName="PlanningServiceProviderID" [appIncludeIf]="datafor=='EModulePlanning' || datafor=='ServiceProvider'">
              <option value='0'>
                Select {{
valueforid=='1'?"Asha Name":(valueforid=='8'?"HCP Name":(valueforid=='9'?"CV
                Name":""))
                }}
              </option>
              <option *ngFor="let serviceprovider of ModalServiceProviders || []" [value]="serviceprovider.ID">
                {{
                serviceprovider.ServiceProvider_Name
                }}
              </option>
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaff' || datafor=='ServiceProvider'">
          <div class='form-group'>
            <label for="stafftype">{{datafor=='ProjectStaff'?'Joining Date':'Date Of Engagement'}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="ModalJoiningDate" maxlength="10" [appIncludeIf]="datafor=='ProjectStaff' || datafor=='ServiceProvider'"
                   (keydown)="onKeyDown($event,'ModalJoiningDate')" (keyup)="onKeyUp($event,'ModalJoiningDate')"
                   [appCustomValidation]="[
              { type: 'date', maxDate: CurDate, errorMessage: 'Please enter a valid date before ' + CurDate }
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['ModalJoiningDate'].errors">

              <div *ngIf="selectsForm.controls['ModalJoiningDate'].errors['date'].maxDate">
                Please enter a date before {{
                CurDate | date:'dd-MMM-yyyy'
                }}.
              </div>
              <div *ngIf="selectsForm.controls['ModalJoiningDate'].errors['required']">Enter {{datafor=='ProjectStaff'?'Joining Date':'Date Of Engagement'}}</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaff'">
          <div class='form-group'>
            <label for="stafftype">Leaving Date(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="ModalLeavingDate" maxlength="10" [appIncludeIf]="datafor=='ProjectStaff'"
                   (keydown)="onKeyDown($event,'ModalLeavingDate')" (keyup)="onKeyUp($event,'ModalLeavingDate')"
                   [appCustomValidation]="[
              { type: 'date', maxDate: CurDate, errorMessage: 'Please enter a valid date before ' + CurDate }
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['ModalLeavingDate'].errors">
              <div *ngIf="selectsForm.controls['ModalLeavingDate'].errors['date'].maxDate">
                Please enter a date before {{
                CurDate | date:'dd-MMM-yyyy'
                }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaff'">
          <div class='form-group'>
            <label for="stafftype">Posting Location</label>
            <input class='form-control' type='text' formControlName='ModalPostingLocation' [appIncludeIf]="datafor=='ProjectStaff'" required>
            <div class="font-red" *ngIf="selectsForm.controls['ModalPostingLocation'].errors">
              <div *ngIf="selectsForm.controls['ModalPostingLocation'].errors['required']">Enter Posting Location.</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ServiceProvider'">
          <div class='form-group'>
            <label for="stafftype">{{ modaltitle}} ID</label>
            <input class='form-control' type='text' formControlName='ModalProviderID' [appIncludeIf]="datafor=='ServiceProvider'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ServiceProvider'">
          <div class='form-group'>
            <label for="stafftype">{{ modaltitle}} Status</label>
            <select class='form-control' formControlName="ModalProviderStatus" [appIncludeIf]="datafor=='ServiceProvider'">
              <option value=''>Select Provider Status</option>
              <option value='ACTIVE'>ACTIVE</option>
              <option value='INACTIVE'>INACTIVE</option>
              <option value='Volunteer-Led Model'   *ngIf="datafor=='ServiceProvider' && valuefor=='9'">Volunteer-Led Model</option>
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaffType' || datafor=='ActivityType'">
          <div class='form-group'>
            <label for="stafftype">Display in App</label>
            <select class='form-control' formControlName="ModalAppStatus" [appIncludeIf]="datafor=='ProjectStaffType' || datafor=='ActivityType'">
              <option value=''>Select Display in App</option>
              <option value='YES'>YES</option>
              <option value='NO'>NO</option>
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ServiceProvider' && selectsForm.controls['ModalProviderStatus'].value=='INACTIVE'">
          <div class='form-group'>
            <label for="stafftype">Reason</label>
            <input class='form-control' type='text' formControlName='ModalStatusReason'  [appIncludeIf]="datafor=='ServiceProvider'" required>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ProjectStaff'">
          <div class='form-group'>
            <label>Photo</label>
            <app-file-upload [accept]="'image/*'" [maxSizePerFile]="1048576" [selectedFiles]="ModalPhotos"
                             (filesChange)="onFilesChange($event)"></app-file-upload>
          </div>
        </div>
        <div class='col-md-6' *ngIf="(datafor=='EModulePlanning' && valuefor=='NIV')">
          <div class='form-group'>
            <label>Slum Name</label>
            <input class='form-control' type='text' formControlName="NIVSlumName" [appIncludeIf]="datafor=='EModulePlanning' && valuefor=='NIV'" required>
          </div>
        </div>
        <div class='col-md-6' *ngIf="(datafor=='EModulePlanning' && valuefor=='NIV')">
          <div class='form-group'>
            <label>Asha Name</label>
            <input class='form-control' type='text' formControlName="NIVAshaName" [appIncludeIf]="datafor=='EModulePlanning' && valuefor=='NIV'" required>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SchoolEventPlanning'">
          <div class='form-group'>
            <label>School Name</label>
            <select class='form-control' formControlName="PlanningSchoolID" [appIncludeIf]="datafor=='SchoolEventPlanning'">
              <option value='0'>Select School</option>
              <option *ngFor="let school of schooldata || []" [value]="school.ID">{{ school.School_Name }}</option>
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="(datafor=='EModulePlanning')">
          <div class='form-group'>
            <label>Training Type</label>
            <select class='form-control' formControlName="TrainingType" [appIncludeIf]="datafor=='EModulePlanning'">
              <option value='0'>Select Training type</option>";
              <option value='Physical'>Physical</option>";
              <option value='Mobile'>Mobile</option>";
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="(datafor=='AdministrativeArea')">
          <div class='form-group'>
            <label>Slum / Village Type</label>
            <select class='form-control' formControlName="AdministrativeAreaType" [appIncludeIf]="datafor=='AdministrativeArea'">
              <option value='0'>Select Slum / Village Type</option>";
              <option value='INTENSIVE'>INTENSIVE</option>";
              <option value='SCALEUP'>SCALEUP</option>";
              <option value='CIVIC'>CIVIC</option>";
              <option value='PHASE OUT'>PHASE OUT</option>";
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea' && selectsForm.controls['AdministrativeAreaType'].value!='INTENSIVE'">
          <div class='form-group'>
            <label>Number of Household</label>
            <input class='form-control' type='text' formControlName="ModalHouseholdNo" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea' && selectsForm.controls['AdministrativeAreaType'].value!='INTENSIVE'">
          <div class='form-group'>
            <label>Number of Population</label>
            <input class='form-control' type='text' formControlName="ModalPopulation" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Latitude</label>
            <input class='form-control' type='text' formControlName="ModalLatitude" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Longitude</label>
            <input class='form-control' type='text' formControlName="ModalLongitude" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>PinCode</label>
            <input class='form-control' type='text' formControlName="ModalPINCode" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Slum Code</label>
            <input class='form-control' type='text' formControlName="ModalSlumCode" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Date of Inception(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="ModalInceptionDate" maxlength="10" [appIncludeIf]="datafor=='AdministrativeArea'"
                   (keydown)="onKeyDown($event,'ModalInceptionDate')" (keyup)="onKeyUp($event,'ModalInceptionDate')"
                   [appCustomValidation]="[
              { type: 'date', maxDate: CurDate, errorMessage: 'Please enter a valid date before ' + CurDate }
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['ModalInceptionDate'].errors">
              <div *ngIf="selectsForm.controls['ModalInceptionDate'].errors['date'].maxDate">
                Please enter a date before
                {{ CurDate | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Date of Phase-out(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="ModalPhaseOutDate" maxlength="10" [appIncludeIf]="datafor=='AdministrativeArea'"
                   (keydown)="onKeyDown($event,'ModalPhaseOutDate')" (keyup)="onKeyUp($event,'ModalPhaseOutDate')"
                   [appCustomValidation]="[
              { type: 'date', maxDate: CurDate, errorMessage: 'Please enter a valid date before ' + CurDate }
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['ModalPhaseOutDate'].errors">
              <div *ngIf="selectsForm.controls['ModalPhaseOutDate'].errors['date'].maxDate">
                Please enter a date before
                {{ CurDate | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Slum Layout Photo</label>
            <app-file-upload [accept]="'image/*'" [captionhidestatus]="true" [datehidestatus]="true" [maxSizePerFile]="1048576" [selectedFiles]="ModalSlumPhotos"
                             (filesChange)="onFilesChange($event)"></app-file-upload>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Slum Layout Date (Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="ModalLayoutDate" maxlength="10" [appIncludeIf]="datafor=='AdministrativeArea'"
                   (keydown)="onKeyDown($event,'ModalLayoutDate')" (keyup)="onKeyUp($event,'ModalLayoutDate')"
                   [appCustomValidation]="[
              { type: 'date', maxDate: CurDate, errorMessage: 'Please enter a valid date before ' + CurDate }
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['ModalLayoutDate'].errors">
              <div *ngIf="selectsForm.controls['ModalLayoutDate'].errors['date'].maxDate">
                Please enter a date before {{
                CurDate | date:'dd-MMM-yyyy'
                }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Slum Layout Helper</label>
            <input class='form-control' type='text' formControlName="ModalLayoutHelper" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Name of Sub-Health Center</label>
            <input class='form-control' type='text' formControlName="Modalshcname" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Distance of Sub-Health Center (in Km)</label>
            <input class='form-control' type='text' formControlName="Modalshcdist" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Name of Primary/Community Health Center</label>
            <input class='form-control' type='text' formControlName="Modalphcname" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Distance of Primary/Community Health Center (in Km)</label>
            <input class='form-control' type='text' formControlName="Modalphcdist" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Name of District Hospital</label>
            <input class='form-control' type='text' formControlName="Modaldistrhospname" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Distance of District Hospital (in Km)</label>
            <input class='form-control' type='text' formControlName="Modaldistrhospdist" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Name of Private Hospital</label>
            <input class='form-control' type='text' formControlName="Modalprivhospname" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <label>Distance of Private Hospital (in Km)</label>
            <input class='form-control' type='text' formControlName="Modalprivhospdist" [appIncludeIf]="datafor=='AdministrativeArea'">
          </div>
        </div>

        <div class='col-md-12' *ngIf="datafor=='AdministrativeArea'">
          <div class='form-group'>
            <input type='submit' style="width:125px;" value='Add Cases Data' class='btn btn-warning btn-block' (click)="AddCasesData(null);">
          </div>
        </div>
        <div class="col-md-12" *ngIf="datafor=='AdministrativeArea'">
          <table width="100%" class="table table-bordered table-striped table-condensed flip-content" formArrayName="AdministrativeCaseData">
            <tr>
              <th>S.No.</th>
              <th>Year</th>
              <th>Number of Malaria Cases</th>
              <th>Number of Dengue Cases</th>
            </tr>
            <tbody *ngFor="let CasesDetail of myCaseList.controls; let i = index">
              <tr [formGroupName]="i">
                <td>{{i+1}}</td>
                <td>
                  <select class='form-control' formControlName="ModalCaseYear">
                    <option selected='selected' value=''>Select Case Year</option>
                    <option value="{{row.CaseYear}}" *ngFor="let row of masterCaseYears">{{row.CaseYear}}</option>
                  </select>
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="ModalMalariaCases" restrict-alphanumeric>
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="ModalDengueCases" restrict-alphanumeric>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>Distance O-L1 (in Km)</label>
            <input class='form-control' type='text' formControlName="ModalDistanceO_L1" restrict-numeric [appIncludeIf]="datafor=='SSVisitPlanning'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>Distance L1-L2 (in Km)</label>
            <input class='form-control' type='text' formControlName="ModalDistanceL1_L2" restrict-numeric [appIncludeIf]="datafor=='SSVisitPlanning'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>Distance L2-O (in Km)</label>
            <input class='form-control' type='text' formControlName="ModalDistanceL2_O" restrict-numeric [appIncludeIf]="datafor=='SSVisitPlanning'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>Time From</label>
            <input type="time" class="form-control" formControlName="ModalSSTimeFrom" [appIncludeIf]="datafor=='SSVisitPlanning'" />
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>Time To</label>
            <input type="time" class="form-control" formControlName="ModalSSTimeTo" [appIncludeIf]="datafor=='SSVisitPlanning'" />

          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>Staff Type</label>
            <select class='form-control' formControlName="ModalSSStaffType" [appIncludeIf]="datafor=='SSVisitPlanning'">
              <option value="0">Select Outlet Type</option>
              <option *ngFor="let ProjectStaffType of masterProjectStaffType" [value]="ProjectStaffType.ProjectStaffType_id">
                {{
                ProjectStaffType.ProjectStaffType_Name
                }}
              </option>
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>Vehicle</label>
            <select class='form-control' formControlName="ModalSSVehicle" [appIncludeIf]="datafor=='SSVisitPlanning'">
              <option selected='selected' value=''>--Select Vehicle--</option>";
              <option value='Own vehicle'>1. Own vehicle</option>";
              <option value='Public Transport'>2. Public Transport</option>";
              <option value='Hired vehicle'>3. Hired vehicle</option>";
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>Activity Type</label>
            <select class='form-control' formControlName="ModalSSActivity" [appIncludeIf]="datafor=='SSVisitPlanning'">
              <option value='0'>Select Activity Type</option>
              <option *ngFor="let acttype of activitytypes || []" [value]="acttype.ActivityType_id">
                {{
                  acttype.ActivityType_Name
                }}
              </option>
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>
              Supportive Supervision Visit Planning Date(Required yyyy-MM-dd format. Dashes will come up automatically)*
            </label>
            <input class='form-control' type='text' formControlName="ModalSSVisitDate" maxlength="10" required [appIncludeIf]="datafor=='SSVisitPlanning'"
                   (keydown)="onKeyDown($event,'ModalSSVisitDate')" (keyup)="onKeyUp($event,'ModalSSVisitDate')"
                   [appCustomValidation]="[
            { type: 'date', minDate: Q1Start, maxDate: Q4End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['ModalSSVisitDate'].errors">
              <div class="font-red" *ngIf="selectsForm.controls['ModalSSVisitDate'].errors['date']">
                <div *ngIf="selectsForm.controls['ModalSSVisitDate'].errors['date'].minDate">
                  Please enter a date after
                  {{ Q1Start | date:'dd-MMM-yyyy' }}.
                </div>
                <div *ngIf="selectsForm.controls['ModalSSVisitDate'].errors['date'].maxDate">
                  Please enter a date
                  before {{ Q4End | date:'dd-MMM-yyyy' }}.
                </div>
              </div>
              <div *ngIf="selectsForm.controls['ModalSSVisitDate']?.value==''">
                Enter Supportive Supervision Visit Planning Date.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>Execution status</label>
            <input class='form-control' type='text' formControlName="ModalExecutionStatus" restrict-alphanumeric [appIncludeIf]="datafor=='SSVisitPlanning'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SSVisitPlanning'">
          <div class='form-group'>
            <label>Reason for deviation from original plan</label>
            <input class='form-control' type='text' formControlName="ModalDeviationReason" [appIncludeIf]="datafor=='SSVisitPlanning'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="(datafor=='WaterStagnation')">
          <div class='form-group'>
            <label>WaterStagnation No.</label>
            <input class='form-control' type='text' formControlName="ModalWaterStagnationNo" [appIncludeIf]="(datafor=='WaterStagnation')" required>
            <div class="font-red" *ngIf="selectsForm.controls['ModalWaterStagnationNo'].errors">
              <div *ngIf="selectsForm.controls['ModalWaterStagnationNo'].errors['required']">Please Enter {{modaltitle}} No.</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='WaterStagnation'">
          <div class='form-group'>
            <label>Larva Status</label>
            <select class='form-control' formControlName="ModalLarvaStatus" [appIncludeIf]="datafor=='WaterStagnation'">
              <option value=''>Select Larva Status</option>
              <option value='YES'>YES</option>
              <option value='NO'>NO</option>
            </select>
            <div class="font-red" *ngIf="selectsForm.controls['ModalLarvaStatus'].errors">
              <div *ngIf="selectsForm.controls['ModalLarvaStatus'].errors['required']">Please Select Larva Status</div>
            </div>
          </div>
        </div>
        <div class='col-md-12' *ngIf="datafor=='HouseHold' && selectsForm.controls['UpdateID'].value=='0'">
          <div class='form-group'>
            <input type='submit' style="width:125px;" value='Add household' class='btn btn-warning btn-block' (click)="AddHouseholdData(null);">
            <div class="font-red" *ngIf="myHHList.length==0  && customvalidationerr">
              Please Add some Household
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="datafor=='HouseHold' && selectsForm.controls['UpdateID'].value=='0'">
          <table width="100%" class="table table-bordered table-striped table-condensed flip-content" formArrayName="HouseholdDetails">
            <tr>
              <th>S.No.</th>
              <th>Household No.</th>
              <th>Structure No.</th>
              <th>No.of Member</th>
              <th>Household Head Name</th>
              <th>Mobile</th>
              <th>Rented</th>
              <th>Rented Date (Required yyyy-MM-dd format. Dashes will come up automatically)</th>
            </tr>
            <tbody *ngFor="let HouseholdDetail of myHHList.controls; let i = index">
              <tr [formGroupName]="i">
                <td>{{i+1}}</td>
                <td>
                  <input class='form-control' type='text' formControlName="HouseHoldNo">
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="StructureNo">
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="Noofmember">
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="HouseHoldname">
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="HHMobile">
                </td>
                <td>
                  <select class='form-control' formControlName="Rented">
                    <option value="">Select Rented</option>
                    <option value='Y'>Y</option>
                    <option value='N'>N</option>
                  </select>
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="Renteddate" maxlength="10"
                         (keydown)="onKeyDown($event,'Renteddate')" (keyup)="onKeyUpInner($event,'Renteddate',i)"
                         [appCustomValidation]="[
                { type: 'date', maxDate: CurDate, errorMessage: 'Please enter a valid date before ' + CurDate }
              ]" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class='col-md-6' *ngIf="datafor=='HouseHold' && selectsForm.controls['UpdateID'].value!='0'">
          <div class='form-group'>
            <label>{{modaltitle}} No.</label>
            <input class='form-control' type='text' formControlName="ModalHouseHoldNo" [appIncludeIf]="(datafor=='HouseHold' || datafor=='WaterStagnation')" required>
            <div class="font-red" *ngIf="selectsForm.controls['ModalHouseHoldNo'].errors">
              <div *ngIf="selectsForm.controls['ModalHouseHoldNo'].errors['required']">Please Enter {{modaltitle}} No.</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='HouseHold' && selectsForm.controls['UpdateID'].value!='0'">
          <div class='form-group'>
            <label>Stucture No.</label>
            <input class='form-control' type='text' formControlName="ModalStructureNo" [appIncludeIf]="datafor=='HouseHold'" required>
            <div class="font-red" *ngIf="selectsForm.controls['ModalStructureNo'].errors">
              <div *ngIf="selectsForm.controls['ModalStructureNo'].errors['required']">Please Enter Stucture No.</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='HouseHold' && selectsForm.controls['UpdateID'].value!='0'">
          <div class='form-group'>
            <label>No.of Member</label>
            <input class='form-control' type='text' formControlName="ModalNoofmember" [appIncludeIf]="datafor=='HouseHold'" required>
            <div class="font-red" *ngIf="selectsForm.controls['ModalNoofmember'].errors">
              <div *ngIf="selectsForm.controls['ModalNoofmember'].errors['required']">Please Enter No.of Member</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='HouseHold' && selectsForm.controls['UpdateID'].value!='0'">
          <div class='form-group'>
            <label>Household Head Name</label>
            <input class='form-control' type='text' formControlName="ModalHouseHoldname" [appIncludeIf]="datafor=='HouseHold'" required>
            <div class="font-red" *ngIf="selectsForm.controls['ModalHouseHoldname'].errors">
              <div *ngIf="selectsForm.controls['ModalHouseHoldname'].errors['required']">Please Enter HouseHold Head Name</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='HouseHold' && selectsForm.controls['UpdateID'].value!='0'">
          <div class='form-group'>
            <label for="stafftype">Mobile</label>
            <input class='form-control' type='text' formControlName='ModalHHMobile' restrict-numeric [appIncludeIf]="datafor=='HouseHold'">
          </div>
        </div>
        <div class="col-md-6" *ngIf="datafor=='HouseHold' && selectsForm.controls['UpdateID'].value!='0'">
          <div class="form-group">
            <label for="cluster">Rented</label>
            <select class='form-control' formControlName="ModalRented" [appIncludeIf]="datafor=='HouseHold'" required>
              <option value="">Select Rented</option>
              <option value='Y'>Y</option>
              <option value='N'>N</option>
            </select>
            <div class="font-red" *ngIf="selectsForm.controls['ModalRented'].errors">
              <div *ngIf="selectsForm.controls['ModalRented'].errors['required']">Please Select Rented</div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='HouseHold' && selectsForm.controls['UpdateID'].value!='0' && selectsForm.controls['ModalRented'].value=='Y'">
          <div class='form-group'>
            <label>Rented Date (Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="ModalRenteddate" maxlength="10" [appIncludeIf]="datafor=='HouseHold'"
                   (keydown)="onKeyDown($event,'ModalRenteddate')" (keyup)="onKeyUp($event,'ModalRenteddate')"
                   [appCustomValidation]="[
              { type: 'date', maxDate: CurDate, errorMessage: 'Please enter a valid date before ' + CurDate }
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['ModalRenteddate'].errors">
              <div *ngIf="selectsForm.controls['ModalRenteddate'].errors['date'].maxDate">
                Please enter a date before
                {{ CurDate | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="(datafor=='ChaupalPlanning' && valuefor=='CommunicationActivity')">
          <div class="form-group">
            <label for="cluster">Activity Type</label>
            <select class='form-control' id="cluster" formControlName="PlanningFor" [appIncludeIf]="datafor=='ChaupalPlanning' && valuefor=='CommunicationActivity'">
              <option value="">Select Activity Type</option>
              <option value='Communication Van'>Communication Van</option>
              <option value='Nukkad Natak'>Nukkad Natak</option>
              <option value='Munadi'>Munadi</option>
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="(datafor=='HNDPlanning')">
          <div class='form-group'>
            <label>HND Site</label>
            <select class='form-control' id="hndsite" formControlName="HNDSiteID" [appIncludeIf]="datafor=='HNDPlanning'">
              <option value="0">Select HND Site</option>
              <option *ngFor="let hndsite of hndsites || []" [value]="hndsite.ID">
                {{ hndsite.HND_Location }}&nbsp;({{
                hndsite.FixHND_Day
                }})
              </option>
            </select>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='ChaupalPlanning' || datafor=='SchoolEventPlanning' || (datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning' || datafor=='EModulePlanning')">
          <div class='form-group'>
            <label>Financial Year</label>
            <select class='form-control' formControlName="FinancialYear" [appIncludeIf]="datafor=='ChaupalPlanning' || datafor=='SchoolEventPlanning' || datafor=='EModulePlanning' || datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning'">
              <option value='0'>Select Financial Year</option>";
              <option value='2023-2024' *ngIf="userlogin.logintype=='Admin'">2023-2024</option>";
              <option value='2024-2025'>2024-2025</option>";
            </select>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='ChaupalPlanning' || datafor=='SchoolEventPlanning' || datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning' || datafor=='EModulePlanning')">
          <div class='form-group'>
            <label>Date of 1st {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="FirstPlanningDate" maxlength="10" [appIncludeIf]="datafor=='ChaupalPlanning' || datafor=='SchoolEventPlanning' || datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning' || datafor=='EModulePlanning'"
                   (keydown)="onKeyDown($event,'FirstPlanningDate')" (keyup)="onKeyUp($event,'FirstPlanningDate')"
                   [appCustomValidation]="[
              { type: 'date', minDate: Q1Start, maxDate: Q1End, errorMessage: 'Please enter a valid date between ' + Q1Start + ' and ' + Q1End }
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['FirstPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['FirstPlanningDate'].errors['date'].minDate">
                Please enter a date after {{
                Q1Start | date:'dd-MMM-yyyy'
                }}.
              </div>
              <div *ngIf="selectsForm.controls['FirstPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ Q1End | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='ChaupalPlanning' || datafor=='SchoolEventPlanning' || datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning' || datafor=='EModulePlanning')">
          <div class='form-group'>
            <label>Date of 2nd {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="SecondPlanningDate" maxlength="10" [appIncludeIf]="datafor=='ChaupalPlanning' || datafor=='SchoolEventPlanning' || datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning' || datafor=='EModulePlanning'"
                   (keydown)="onKeyDown($event,'SecondPlanningDate')" (keyup)="onKeyUp($event,'SecondPlanningDate')"
                   [appCustomValidation]="[
              { type: 'date', minDate: (datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q2Start : Q1Start, maxDate: (datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q2End : Q1End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['SecondPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['SecondPlanningDate'].errors['date'].minDate">
                Please enter a date after
                {{ ((datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q2Start : Q1Start) | date:'dd-MMM-yyyy' }}.
              </div>
              <div *ngIf="selectsForm.controls['SecondPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ ((datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q2End : Q1End) | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='ChaupalPlanning' || datafor=='SchoolEventPlanning' || datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning' || datafor=='EModulePlanning')">
          <div class='form-group'>
            <label>Date of 3rd {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="ThirdPlanningDate" maxlength="10" [appIncludeIf]="datafor=='ChaupalPlanning' || datafor=='SchoolEventPlanning' || datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning' || datafor=='EModulePlanning'"
                   (keydown)="onKeyDown($event,'ThirdPlanningDate')" (keyup)="onKeyUp($event,'ThirdPlanningDate')"
                   [appCustomValidation]="[
              { type: 'date', minDate: (datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q3Start : Q1Start, maxDate: (datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q3End : Q1End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['ThirdPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['ThirdPlanningDate'].errors['date'].minDate">
                Please enter a date after {{
                ((datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q3Start : Q1Start) | date:'dd-MMM-yyyy'
                }}.
              </div>
              <div *ngIf="selectsForm.controls['ThirdPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ ((datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q3End : Q1End) | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="datafor=='ChaupalPlanning' || datafor=='SchoolEventPlanning' || datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning' || datafor=='EModulePlanning'">
          <div class='form-group'>
            <label>Date of 4th {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="FourthPlanningDate" maxlength="10" [appIncludeIf]="datafor=='ChaupalPlanning' || datafor=='SchoolEventPlanning' || datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning' || datafor=='EModulePlanning'"
                   (keydown)="onKeyDown($event,'FourthPlanningDate')" (keyup)="onKeyUp($event,'FourthPlanningDate')"
                   [appCustomValidation]="[
              { type: 'date', minDate: (datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q4Start : Q2Start, maxDate: (datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q4End : Q2End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['FourthPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['FourthPlanningDate'].errors['date'].minDate">
                Please enter a date after
                {{ ((datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q4Start : Q2Start) | date:'dd-MMM-yyyy' }}.
              </div>
              <div *ngIf="selectsForm.controls['FourthPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ ((datafor === 'ChaupalPlanning' || datafor=='EModulePlanning' || datafor=='SchoolEventPlanning') ? Q4End : Q2End) | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')">
          <div class='form-group'>
            <label>Date of 5th {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="FifthPlanningDate" maxlength="10" [appIncludeIf]="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')"
                   (keydown)="onKeyDown($event,'FifthPlanningDate')" (keyup)="onKeyUp($event,'FifthPlanningDate')"
                   [appCustomValidation]="[
            { type: 'date', minDate: Q2Start, maxDate: Q2End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['FifthPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['FifthPlanningDate'].errors['date'].minDate">
                Please enter a date after {{
                Q2Start | date:'dd-MMM-yyyy'
                }}.
              </div>
              <div *ngIf="selectsForm.controls['FifthPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ Q2End | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')">
          <div class='form-group'>
            <label>Date of 6th {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="SixthPlanningDate" maxlength="10" [appIncludeIf]="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')"
                   (keydown)="onKeyDown($event,'SixthPlanningDate')" (keyup)="onKeyUp($event,'SixthPlanningDate')"
                   [appCustomValidation]="[
            { type: 'date', minDate: Q2Start, maxDate: Q2End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['SixthPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['SixthPlanningDate'].errors['date'].minDate">
                Please enter a date after {{
                Q2Start | date:'dd-MMM-yyyy'
                }}.
              </div>
              <div *ngIf="selectsForm.controls['SixthPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ Q2End | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')">
          <div class='form-group'>
            <label>Date of 7th {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="SeventhPlanningDate" maxlength="10" [appIncludeIf]="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')"
                   (keydown)="onKeyDown($event,'SeventhPlanningDate')" (keyup)="onKeyUp($event,'SeventhPlanningDate')"
                   [appCustomValidation]="[
            { type: 'date', minDate: Q3Start, maxDate: Q3End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['SeventhPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['SeventhPlanningDate'].errors['date'].minDate">
                Please enter a date after
                {{ Q3Start | date:'dd-MMM-yyyy' }}.
              </div>
              <div *ngIf="selectsForm.controls['SeventhPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ Q3End | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')">
          <div class='form-group'>
            <label>Date of 8th {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="EighthPlanningDate" maxlength="10" [appIncludeIf]="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')"
                   (keydown)="onKeyDown($event,'EighthPlanningDate')" (keyup)="onKeyUp($event,'EighthPlanningDate')"
                   [appCustomValidation]="[
            { type: 'date', minDate: Q3Start, maxDate: Q3End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['EighthPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['EighthPlanningDate'].errors['date'].minDate">
                Please enter a date after
                {{ Q3Start | date:'dd-MMM-yyyy' }}.
              </div>
              <div *ngIf="selectsForm.controls['EighthPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ Q3End | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')">
          <div class='form-group'>
            <label>Date of 9th {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="NinthPlanningDate" maxlength="10" [appIncludeIf]="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')"
                   (keydown)="onKeyDown($event,'NinthPlanningDate')" (keyup)="onKeyUp($event,'NinthPlanningDate')"
                   [appCustomValidation]="[
            { type: 'date', minDate: Q3Start, maxDate: Q3End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['NinthPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['NinthPlanningDate'].errors['date'].minDate">
                Please enter a date after {{
                Q3Start | date:'dd-MMM-yyyy'
                }}.
              </div>
              <div *ngIf="selectsForm.controls['NinthPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ Q3End | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')">
          <div class='form-group'>
            <label>Date of 10th {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="TenthPlanningDate" maxlength="10" [appIncludeIf]="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')"
                   (keydown)="onKeyDown($event,'TenthPlanningDate')" (keyup)="onKeyUp($event,'TenthPlanningDate')"
                   [appCustomValidation]="[
            { type: 'date', minDate: Q4Start, maxDate: Q4End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['TenthPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['TenthPlanningDate'].errors['date'].minDate">
                Please enter a date after {{
                Q4Start | date:'dd-MMM-yyyy'
                }}.
              </div>
              <div *ngIf="selectsForm.controls['TenthPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ Q4End | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')">
          <div class='form-group'>
            <label>Date of 11th {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="EleventhPlanningDate" maxlength="10" [appIncludeIf]="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')"
                   (keydown)="onKeyDown($event,'EleventhPlanningDate')" (keyup)="onKeyUp($event,'EleventhPlanningDate')"
                   [appCustomValidation]="[
            { type: 'date', minDate: Q4Start, maxDate: Q4End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['EleventhPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['EleventhPlanningDate'].errors['date'].minDate">
                Please enter a date after
                {{ Q4Start | date:'dd-MMM-yyyy' }}.
              </div>
              <div *ngIf="selectsForm.controls['EleventhPlanningDate'].errors['date'].maxDate">
                Please enter a date
                before {{ Q4End | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6'
             *ngIf="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')">
          <div class='form-group'>
            <label>Date of 12th {{modaltitle}}(Required yyyy-MM-dd format. Dashes will come up automatically)</label>
            <input class='form-control' type='text' formControlName="TwelvthPlanningDate" maxlength="10" [appIncludeIf]="(datafor=='HNDPlanning' || datafor=='ActivityPlanning' || datafor=='MeetingPlanning')"
                   (keydown)="onKeyDown($event,'TwelvthPlanningDate')" (keyup)="onKeyUp($event,'TwelvthPlanningDate')"
                   [appCustomValidation]="[
              { type: 'date', minDate: Q4Start, maxDate: Q4End}
            ]" />
            <div class="font-red" *ngIf="selectsForm.controls['TwelvthPlanningDate'].errors">
              <div *ngIf="selectsForm.controls['TwelvthPlanningDate'].errors['date'].minDate">
                Please enter a date after
                {{ Q4Start | date:'dd-MMM-yyyy' }}.
              </div>
              <div *ngIf="selectsForm.controls['TwelvthPlanningDate'].errors['date'].maxDate">
                Please enter a date before
                {{ Q4End | date:'dd-MMM-yyyy' }}.
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SchoolData'">
          <div class='form-group'>
            <label>School code/ DISE code</label>
            <input class='form-control' type='text' formControlName="ModalSchoolCode" [appIncludeIf]="datafor=='SchoolData'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SchoolData'">
          <div class='form-group'>
            <label>School Name</label>
            <input class='form-control' type='text' formControlName="ModalSchoolname" restrict-alphanumeric [appIncludeIf]="datafor=='SchoolData'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SchoolData'">
          <div class='form-group'>
            <label>School Category</label>
            <div id='dvSchoolCategory'>
              <select class='form-control' formControlName="ModalSchoolCategory" (change)="onSchoolCategoryChange();" [appIncludeIf]="datafor=='SchoolData'">
                <option selected='selected' value=''>Select School Category</option>
                <option value="{{row.SchoolCategory}}" *ngFor="let row of masterSchoolCategory">{{row.SchoolCategory}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SchoolData'">
          <div class='form-group'>
            <label>School Management</label>
            <div id='dvSchoolManagement'>
              <select class='form-control' formControlName="ModalSchoolManagement" [appIncludeIf]="datafor=='SchoolData'">
                <option selected='selected' value=''>Select School Management</option>
                <option value="{{row.SchoolManagement}}" *ngFor="let row of masterSchoolManagement">{{row.SchoolManagement}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SchoolData'">
          <div class='form-group'>
            <label>Name of Principal</label>
            <input class='form-control' type='text' formControlName="ModalPrincipalName" restrict-alphanumeric [appIncludeIf]="datafor=='SchoolData'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SchoolData'">
          <div class='form-group'>
            <label>Contact number of Principal</label>
            <input class='form-control' type='text' formControlName="ModalPrincipalMobile" restrict-numeric [appIncludeIf]="datafor=='SchoolData'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SchoolData'">
          <div class='form-group'>
            <label>Number of Teachers - Male</label>
            <input class='form-control' type='text' formControlName="ModalTeachersMale" restrict-numeric [appIncludeIf]="datafor=='SchoolData'">
          </div>
        </div>
        <div class='col-md-6' *ngIf="datafor=='SchoolData'">
          <div class='form-group'>
            <label>Number of Teachers - Female</label>
            <input class='form-control' type='text' formControlName="ModalTeachersFemale" restrict-numeric [appIncludeIf]="datafor=='SchoolData'">
          </div>
        </div>
        <div class='col-md-12' *ngIf="datafor=='SchoolData'">
          <div class='form-group'>
            <input type='submit' style="width:125px;" value='Add Class Data' class='btn btn-warning btn-block' (click)="AddClassData(null);">
          </div>
        </div>
        <div class="col-md-12" *ngIf="datafor=='SchoolData'">
          <table width="100%" class="table table-bordered table-striped table-condensed flip-content" formArrayName="ClassDetails">
            <tr>
              <th>S.No.</th>
              <th>Student Class</th>
              <th>Student Section (if any)</th>
              <th>Class Teacher Name</th>
              <th>Class Teacher Mobile</th>
              <th># Student (Male)</th>
              <th># Student (Female)</th>
              <th>Student Malaria Warrior Name</th>
              <th>Student Malaria Warrior Mobile</th>
            </tr>
            <tbody *ngFor="let ClassDetail of myList.controls; let i = index">
              <tr [formGroupName]="i">
                <td>{{i+1}}</td>
                <td>
                  <select class='form-control' formControlName="ModalSchoolClass">
                    <option selected='selected' value=''>Select School Class</option>
                    <option value="{{row.SchoolClass}}" *ngFor="let row of masterSchoolClass">{{row.SchoolClass}}</option>
                  </select>
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="ModalSchoolSection">
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="ModalClassTeacherName" restrict-alphanumeric>
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="ModalClassTeacherMobile" restrict-numeric>
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="ModalStudentMale" restrict-numeric>
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="ModalStudentFemale" restrict-numeric>
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="ModalStudentMalariaWarriorName" restrict-alphanumeric>
                </td>
                <td>
                  <input class='form-control' type='text' formControlName="ModalStudentMalariaWarriorMobile" restrict-numeric>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class='col-md-6' *ngIf="(datafor=='SSVisitPlanning' || datafor=='ActivityPlanning')">
          <div class='form-group'>
            <label>Remark</label>
            <input class='form-control' type='text' formControlName="ModalRemark" restrict-alphanumeric [appIncludeIf]="(datafor=='SSVisitPlanning' || datafor=='ActivityPlanning')">
          </div>
        </div>
        <div class="col-md-12">
          <button type="submit" class="btn btn-primary" (click)="onSubmit()">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>
